import React from "react";
import {
  Datagrid,
  NumberField,
  EditButton,
  List,
  ListProps,
  TextField,
  Filter,
  TextInput,
  FilterProps,
} from "react-admin";

const Filters = (props?: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <TextInput source="name" />
  </Filter>
);

export const SectionList = (props: ListProps) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<Filters />}
  >
    <Datagrid>
      <NumberField source="id" />
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);
