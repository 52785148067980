import React from "react";
import "./App.css";
import { Admin, Resource } from "react-admin";
import dashboard from "./pages/dashboard";
import notFound from "./pages/not-found";
import polyglotI18nProvider from "ra-i18n-polyglot";
import polishMessages from "ra-language-polish";
import i18n from "./i18n";
import { merge } from "lodash";
import { EmployeeList } from "./pages/employee/EmployeeList";
import { EmployeeCreate } from "./pages/employee/EmployeeCreate";
import { EmployeeEdit } from "./pages/employee/EmployeeEdit";
import { Route } from "react-router";
import { EmployeeAddCard } from "./pages/employee/EmployeeAddCard";
import { WorkLogList } from "./pages/work-log/WorkLogList";
import { WorkLogEdit } from "./pages/work-log/WorkLogEdit";
import authProvider from "./helpers/ra-cognito";
import ChangePasswordPage from "./pages/change-password/ChangePassword";
import { CardReaderSynchronize } from "./pages/card-reader/CardReaderSynchronize";
import Calendar from "./pages/calendar/Calendar";
import { WorkLogRecalculate } from "./pages/work-log/WorkLogRecalculate";
import WrappedLayout from "./layout/Layout";
import { WorkDayList } from "./pages/work-day/WorkDayList";
import { WorkDayRecalculate } from "./pages/work-day/WorkDayRecalculate";
import { WorkDayCreate } from "./pages/work-day/WorkDayCreate";
import { WorkDayEdit } from "./pages/work-day/WorkDayEdit";
import PeopleIcon from "@material-ui/icons/People";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { EmployeeView } from "./pages/employee/EmployeeView";
import { DayOffList } from "./pages/day-off/DayOffList";
import { DayOffCreate } from "./pages/day-off/DayOffCreate";
import { DayOffEdit } from "./pages/day-off/DayOffEdit";
import { adminResources } from "./helpers/admin-resources";
import {
  PERMISSION_ADMIN,
  PERMISSION_GENERAL_MANAGER,
} from "./helpers/permissions";
import { AgreementCreate } from "./pages/employee/agreement/AgreementCreate";
import { AgreementEdit } from "./pages/employee/agreement/AgreementEdit";

const jsonapiClient = require("ra-jsonapi-client").default;

const i18nProvider = polyglotI18nProvider(
  () => merge(polishMessages, i18n as any),
  "fr"
);

const settings = {
  headers: {
    Accept: "application/json; charset=utf-8",
    "Content-Type": "application/json; charset=utf-8",
  },
};

function App() {
  return (
    <div className="App">
      <Admin
        dataProvider={(jsonapiClient as any)(
          process.env.REACT_APP_API_URL,
          settings
        )}
        i18nProvider={i18nProvider}
        authProvider={authProvider({
          UserPoolId: "eu-central-1_NP5ktkvNd",
          ClientId: "5dpeaiphc2b218fnaudggram70",
        })}
        title="Dworzysko Overseer"
        dashboard={dashboard}
        catchAll={notFound}
        customRoutes={[
          <Route path={"/add-card/:id"} component={EmployeeAddCard} exact />,
          <Route
            path={"/card-reader-sync/:id"}
            component={CardReaderSynchronize}
            exact
          />,
          <Route
            path={"/change-password"}
            component={ChangePasswordPage}
            exact
          />,
          <Route
            path={"/work-log-recalculate"}
            component={WorkLogRecalculate}
            exact
          />,
          <Route
            path={"/work-day-recalculate"}
            component={WorkDayRecalculate}
            exact
          />,
          <Route path={"/calendar"} component={Calendar} exact />,
        ]}
        layout={WrappedLayout}
      >
        {(permissions) => {

          const hasFullAccess = !!([PERMISSION_ADMIN, PERMISSION_GENERAL_MANAGER].filter(value => permissions.includes(value)).length)

          return [
            <Resource
                name={"employee"}
                list={EmployeeList}
                create={hasFullAccess ? EmployeeCreate : undefined}
                edit={hasFullAccess ? EmployeeEdit : undefined}
                show={EmployeeView}
                icon={() => <PeopleIcon/>}
            />,
            <Resource
                name={"work-log"}
                list={WorkLogList}
                edit={WorkLogEdit}
                icon={() => <ClearAllIcon/>}
            />,
            <Resource
                name={"work-day"}
                list={WorkDayList}
                create={WorkDayCreate}
                edit={WorkDayEdit}
                icon={() => <DateRangeIcon/>}
            />,
            <Resource
                name={"day-off"}
                list={DayOffList}
                create={DayOffCreate}
                edit={DayOffEdit}
                icon={() => <DateRangeIcon/>}
            />,
            <Resource
                name={"agreement"}
                create={hasFullAccess ? AgreementCreate : undefined}
                edit={hasFullAccess ? AgreementEdit : undefined}
            />,
            ...adminResources(permissions),
          ];
        }
        }
      </Admin>
    </div>
  );
}

export default App;
