export default {
  ra: {
    navigation: {
      skip_nav: "Wróć do treści",
    },
    action: {
      unselect: "Odznacz",
    },
  },
  resources: {
    employee: {
      name: "Pracownik |||| Pracownicy",
      fields: {
        firstName: "Imię",
        lastName: "Nazwisko",
        position: "Stanowisko",
        isActive: "Aktywny",
        createdAt: "Data dodania",
        updatedAt: "Data aktualizacji",
        cardId: "Numer karty",
        cardDecId: "Numer z karty",
        companyName: "Nazwa firmy",
        positionName: "Stanowisko",
        companyId: "Firma",
        departmentId: "Departament",
        divisionId: "Dział",
        sectionId: "Sekcja",
        positionId: "Nazwa stanowiska",
      },
    },
    "card-reader": {
      name: "Czytnik |||| Czytniki",
      fields: {
        locationName: "Lokalizacja",
        isActive: "Aktywny",
        createdAt: "Data dodania",
        updatedAt: "Data aktualizacji",
        ip: "IP",
        lastSynced: "Data ostatniej próby synchronizacji",
        lastLogTime: "Data ostatniego odczytu",
        isConnected: "Połączony",
        logs: "Logi",
      },
    },
    "work-log": {
      name: "Odczyt |||| Odczyty z czytników",
      fields: {
        time: "Czas",
        employeeName: "Pracownik",
        cardId: "Numer karty",
        type: "Rodzaj zdarzenia",
        employeeId: "Pracownik",
      },
    },
    "work-day": {
      name: "Dzień pracy |||| Dni pracy",
      fields: {
        employee: "Pracownik",
        startTime: "Godz. wejścia",
        endTime: "Godz. wyjścia",
        type: "Typ",
        employeeId: "Pracownik",
        duration: "Czas pracy",
        date: "Data",
        isDayOff: "Dzień wolny",
        description: "Dodatkowy opis",
      },
    },
    "day-off": {
      name: "Urlop |||| Urlopy",
      fields: {
        employee: "Pracownik",
        startTime: "Dzień rozpoczęcia",
        endTime: "Dzień zakończenia",
        type: "Typ",
        employeeId: "Pracownik",
        duration: "Czas trwania",
        date: "Dzień rozpoczęcia",
        isDayOff: "Dzień wolny",
        description: "Dodatkowy opis",
      },
    },
    company: {
      name: "Firma |||| Firmy",
      fields: {
        name: "Nazwa",
      },
    },
    department: {
      name: "Departament |||| Departamenty",
      fields: {
        name: "Nazwa",
      },
    },
    division: {
      name: "Dział |||| Działy",
      fields: {
        name: "Nazwa",
      },
    },
    section: {
      name: "Sekcja |||| Sekcje",
      fields: {
        name: "Nazwa",
      },
    },
    position: {
      name: "Stanowisko |||| Stanowiska",
      fields: {
        name: "Nazwa",
      },
    },
    agreement: {
      name: "Umowa |||| Umowy",
      fields: {
        companyId: "Firma",
        departmentId: "Departament",
        divisionId: "Dział",
        sectionId: "Sekcja",
        employeeId: "Pracownik",
        positionId: "Nazwa stanowiska",
        position: "Nazwa stanowiska",
        dateFrom: "Data od",
        dateTo: "Data do",
        rate: "Stawka zł (netto)",
        isHourlyRate: "Stawka godzinowa",
      },
    },
  },
  "Agreement can't be created, working time overlaps with another agreement.":
    "Umowa nie może zostać zapisana ponieważ czas jej trwania pokrywa się z czasem trwania innej umowy.",
  "Timeout on request to timesheet":
    "Nie udało się załadować modułu czasu pracy.",
  "Timeout on request to card-reader":
    "Nie udało się załadować modułu czytników.",
};
