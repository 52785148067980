export enum WorkLogActivityType {
  "ENTRY" = "wejście",
  "EXIT" = "wyjście",
}

export const getWorkLogType = (type: keyof WorkLogActivityType): string => {
  switch (type) {
    case "ENTRY":
      return `${WorkLogActivityType.ENTRY}`;
    case "EXIT":
    default:
      return `${WorkLogActivityType.EXIT}`;
  }
};

export type WorkLog = {
    id: number,
    time: Date,
    cardId: string,
    employeeId: number,
    deviceId: number,
    isDeleted: boolean,
    type: WorkLogActivityType,
    employeeName: string
};
