import { mapKeys, mapValues } from "lodash";
import React, { useCallback } from "react";
import {
  BooleanInput,
  Create,
  CreateProps,
  minLength,
  number,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  useMutation,
  useNotify,
  useRedirect,
} from "react-admin";
import { CardNumberInput } from "./components/CardNumberInput";

export const EmployeeCreate = (props: CreateProps) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const [mutate] = useMutation();
  const save = useCallback(
    async (values) => {
      try {
        await mutate(
          {
            type: "create",
            resource: "employee",
            payload: { data: values },
          },
          { returnPromise: true }
        );

        notify("Pracownik został pomyślnie dodany", "info");
        redirect("list", "/employee");
      } catch (error) {
        if (error.message && Array.isArray(error.message.message)) {
          return mapValues(
            mapKeys(error.message.message, (value) =>
              value
                .match(/attributes\.[a-zA-Z]{1,}/)[0]
                .replace("attributes.", "")
            ),
            (value) => value.replace(/attributes\.[a-zA-Z]{1,}\s/, "")
          );
        }

        notify(error && (error.message?.message || error.message), 'error');
      }
    },
    [mutate, redirect, notify]
  );

  return (
    <Create {...props} title={"Dodaj pracownika"}>
      <SimpleForm save={save} initialValues={{ isActive: true }}>
        <NumberInput source="id" validate={[number()]} />
        <TextInput source="firstName" validate={[minLength(3), required()]} />
        <TextInput source="lastName" validate={[minLength(3), required()]} />
        <CardNumberInput source="cardDecId" />
        <BooleanInput source="isActive" />
      </SimpleForm>
    </Create>
  );
};
