import { FormControl, makeStyles, Select, MenuItem } from "@material-ui/core";
import { map } from "lodash";
import { useTimespan } from "../context/TimespanContext";

const months = map(
  {
    0: "styczeń",
    1: "luty",
    2: "marzec",
    3: "kwiecień",
    4: "maj",
    5: "czerwiec",
    6: "lipiec",
    7: "sierpień",
    8: "wrzesień",
    9: "październik",
    10: "listopad",
    11: "grudzień",
  },
  (name, id) => ({ name, id })
);

const FIRST_YEAR = 2021;

const getYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [FIRST_YEAR];
  for (let i = FIRST_YEAR; i < currentYear; i++) {
    years.push(i + 1);
  }

  return years.map((val) => ({ id: val, name: val }));
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
}));

export const TimespanSelect = () => {
  const years = getYears();
  const { month, year, setMonth, setYear } = useTimespan();
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select value={month} onChange={(e) => setMonth(`${e.target.value}`)}>
          {months.map((val, i) => (
            <MenuItem value={val.id} key={i}>{val.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <Select value={year} onChange={(e) => setYear(`${e.target.value}`)}>
          {years.map((val, i) => (
            <MenuItem value={val.id} key={i}>{val.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
