import React from "react";
import {
    BooleanField,
    Datagrid,
    NumberField,
    EditButton,
    FunctionField,
    List,
    ListProps,
    Record,
    TextField,
    Filter,
    TextInput,
    FilterProps,
    BooleanInput,
    ShowButton,
    ReferenceInput,
    SelectInput, usePermissions, ArrayField, SingleFieldList, ChipField,
} from "react-admin";
import {convertHexToDec} from "./helpers/CalculateCardNumber";
import {PERMISSION_ADMIN, PERMISSION_GENERAL_MANAGER} from "../../helpers/permissions";

const Filters = (props?: Omit<FilterProps, "children">) => (
    <Filter {...props}>
        <TextInput source="lastName"/>
        <BooleanInput source="isActive"/>
        <ReferenceInput source="companyId" reference="company">
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput source="departmentId" reference="department">
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput source="divisionId" reference="division">
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput source="sectionId" reference="section">
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput source="positionId" reference="position">
            <SelectInput optionText="name"/>
        </ReferenceInput>
    </Filter>
);

export const EmployeeList = (props: ListProps) => {

    const {loaded, permissions} = usePermissions();

    const hasFullAccess = [PERMISSION_ADMIN, PERMISSION_GENERAL_MANAGER].includes(permissions);

    return (


        <List
            {...props}
            bulkActionButtons={false}
            filters={<Filters/>}
            filterDefaultValues={{isActive: true}}
        >
            <Datagrid>
                <NumberField source="id"/>
                <TextField source="firstName"/>
                <TextField source="lastName"/>
                <ArrayField source="agreements" label="Departament">
                    <SingleFieldList>
                        <ChipField source="department.name" />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="agreements" label="Pozycja">
                    <SingleFieldList>
                        <ChipField source="position.name" />
                    </SingleFieldList>
                </ArrayField>
                <FunctionField
                    source="cardId"
                    render={(record: Record | undefined) => {
                        return record ? (
                            record.cardId ? (
                                <span>{convertHexToDec(record.cardId)}</span>
                            ) : (
                                "-"
                            )
                        ) : null;
                    }
                    }
                />
                {hasFullAccess ? <EditButton/> : null}
                <ShowButton/>
            </Datagrid>
        </List>
    );
};
