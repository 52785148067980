import {getHumanDuration} from "../../components/DurationField";
import React from "react";
import {useHistory} from "react-router-dom";

export interface DayOffCellDataInterface {
    workdayId: string;
    type: number;
    firstName: string;
    lastName: string;
    position: string;
    duration: number;
    description: string;
}


const DayOffCell = ({workdayId, type, firstName, lastName, position, duration, description}: DayOffCellDataInterface) => {

    const history = useHistory();

    return (<div
        onClick={() => history.push(`/day-off/${workdayId}`)}
        style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.5)",
            padding: "3px",
            backgroundColor: "darkcyan",
            cursor: "pointer",
        }}>
        <div
            style={{
                width: "70%",
                display: "flex",
                flexFlow: "column",
                alignItems: "flex-start"
            }}>
            <div
                style={{
                    fontSize: "1.1em",
                    fontWeight: "bold",
                    textOverflow: "ellipsis",
                    width: "100%",
                    overflow: "hidden"
                }}>{`${firstName.substr(0, 1)}. ${lastName}`}</div>
            <div
                style={{
                    fontSize: "0.8em",
                    textOverflow: "ellipsis",
                    width: "100%",
                    overflow: "hidden"
                }}>{position}
            </div>
        </div>
        <div
            style={{
                width: "30%",
                display: "flex",
                flexFlow: "column",
                alignItems: "flex-end"
            }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1.1em",
                    fontWeight: "bold"
                }}>
                {getHumanDuration(duration, true)}
            </div>
            <div
                style={{
                    fontSize: "0.8em",
                }}>
                {type === 2 ? "URLOP" : "L4"}
            </div>
        </div>
    </div>)
};

export default DayOffCell;

