import {
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  Record,
  Edit,
  EditProps,
  useQuery,
  Loading,
  useEditController,
  DateInput,
  NumberInput,
  NullableBooleanInput,
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

const Title = ({ record }: { record?: Record }) => {
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "employee",
    payload: { id: record?.employeeId },
  });

  if (loading) return <Loading />;
  if (!data) return null;
  return (
    <span>
      Umowa pracownika {data ? `${data.firstName} ${data.lastName}` : "-"}
    </span>
  );
};

export const AgreementEdit = (props: EditProps) => {
  const { record } = useEditController(props);
  const classes = useStyles();

  const redirect = `/employee/${record?.employeeId}/show`;

  return (
    <Edit {...props} title={<Title />}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          source="companyId"
          reference="company"
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="departmentId"
          reference="department"
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="divisionId"
          reference="division"
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="sectionId"
          reference="section"
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="positionId"
          reference="position"
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="dateFrom" validate={[required()]} formClassName={classes.inlineBlock}/>
        <DateInput source="dateTo" formClassName={classes.inlineBlock}/>
        <NumberInput source="rate" min={0} step="10" validate={[required()]} formClassName={classes.inlineBlock}/>
        <NullableBooleanInput source="isHourlyRate" validate={[required()]} formClassName={classes.inlineBlock}/>
      </SimpleForm>
    </Edit>
  );
};
