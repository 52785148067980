import {
  Create,
  CreateProps,
  minLength,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";

export const PositionCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={[minLength(3), required()]} />
      </SimpleForm>
    </Create>
  );
};
