import { Card } from "@material-ui/core";
import {
  PasswordInput,
  required,
  SimpleForm,
  TextInput,
  useLogin,
  useNotify,
  email,
  minLength,
  Title,
} from "react-admin";

const ChangePasswordPage = () => {
  const login = useLogin();
  const notify = useNotify();
  const submit = (values: {
    email: string;
    password: string;
    newPassword: string;
  }) => {
    login(values).catch((error) => {
      console.log(error);
      notify("Niepoprawny email lub hasło");
    });
  };

  return (
    <Card>
      <Title title={`Ustaw nowe hasło`} />
      <SimpleForm save={submit}>
        <TextInput source="email" validate={[email(), required()]} />
        <PasswordInput
          label="Stare hasło"
          source="password"
          validate={[minLength(8), required()]}
        />
        <PasswordInput
          label="Nowe hasło"
          source="newPassword"
          validate={[minLength(8), required()]}
        />
      </SimpleForm>
    </Card>
  );
};

export default ChangePasswordPage;
