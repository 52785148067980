import * as React from "react";
import { TextFieldProps, useRecordContext } from "react-admin";

const TimeField = (props: TextFieldProps) => {
  const { source } = props;
  const record = useRecordContext(props);
  return source && record[source] ? (
    <>{new Date(record[source]).toLocaleTimeString().substr(0, 5)}</>
  ) : null;
};

export default TimeField;
