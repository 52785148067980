import { take, takeRight } from "lodash";
import React, { useEffect, useState } from "react";
import { AutocompleteInput, Validator } from "react-admin";
import { WorkDayTypes } from "../pages/work-day/WorkDayType";

export const WorkDaySelect = ({
  source,
  isDayOff,
  ...rest
}: {
  isDayOff: boolean;
  source: string;
  validate?: Validator | Validator[];
}) => {
  const [choices, setChoices] = useState<{ id: number; name: string }[]>([]);

  useEffect(() => {
    const items: { id: number; name: string }[] = [
      ...(isDayOff ? takeRight(WorkDayTypes, 3) : take(WorkDayTypes, 2)),
    ];
    setChoices(items);
  }, [isDayOff]);

  return <AutocompleteInput source={source} choices={choices} {...rest} />;
};
