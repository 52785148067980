import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useQueryWithStore } from "react-admin";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "200px",
  },
}));

interface IdName {
  id: string;
  name: string;
}

interface EmployeeFiltersProps {
  onChange: (values: { [k: string]: string }) => void;
}

export const EmployeeFilters = ({ onChange }: EmployeeFiltersProps) => {
  const classes = useStyles();

  const [values, setValues] = useState<{ [k: string]: string }>({});
  const [companies, setCompanies] = useState<IdName[]>([]);
  const [departments, setDepartments] = useState<IdName[]>([]);
  const [divisions, setDivisions] = useState<IdName[]>([]);
  const [sections, setSections] = useState<IdName[]>([]);

  const setValue = (name: string, value: string) => {
    setValues((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const { data } = useQueryWithStore({
    type: "getList",
    resource: "company",
    payload: {
      pagination: {
        page: 1,
        perPage: 100,
      },
    },
  });
  if (!companies.length && data) setCompanies(data);

  const { data: departmentsData } = useQueryWithStore({
    type: "getList",
    resource: "department",
    payload: {
      pagination: {
        page: 1,
        perPage: 100,
      },
    },
  });
  if (!departments.length && departmentsData) setDepartments(departmentsData);

  const { data: divisionsData } = useQueryWithStore({
    type: "getList",
    resource: "division",
    payload: {
      pagination: {
        page: 1,
        perPage: 100,
      },
    },
  });
  if (!divisions.length && divisionsData) setDivisions(divisionsData);

  const { data: sectionsData } = useQueryWithStore({
    type: "getList",
    resource: "section",
    payload: {
      pagination: {
        page: 1,
        perPage: 100,
      },
    },
  });
  if (!sections.length && sectionsData) setSections(sectionsData);

  useEffect(() => {
    onChange(values);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <div>
      {companies.length > 0 && (
        <FormControl className={classes.formControl}>
          <InputLabel>Firma</InputLabel>
          <Select
            onChange={(e) => setValue("companyId", `${e.target.value}`)}
            value={values.companyId || ""}
          >
            <MenuItem value="">
              <em>- dowolna firma -</em>
            </MenuItem>
            {(companies as IdName[]).map((val, i) => (
              <MenuItem value={val.id} key={i}>
                {val.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {departments.length > 0 && (
        <FormControl className={classes.formControl}>
          <InputLabel>Departament</InputLabel>
          <Select
            onChange={(e) => setValue("departmentId", `${e.target.value}`)}
            value={values.departmentId || ""}
          >
            <MenuItem value="">
              <em>- dowolny departament -</em>
            </MenuItem>
            {(departments as IdName[]).map((val, i) => (
              <MenuItem value={val.id} key={i}>
                {val.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {divisions.length > 0 && (
        <FormControl className={classes.formControl}>
          <InputLabel>Dział</InputLabel>
          <Select
            onChange={(e) => setValue("divisionId", `${e.target.value}`)}
            value={values.divisionId || ""}
          >
            <MenuItem value="">
              <em>- dowolny dział -</em>
            </MenuItem>
            {(divisions as IdName[]).map((val, i) => (
              <MenuItem value={val.id} key={i}>
                {val.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {sections.length > 0 && (
        <FormControl className={classes.formControl}>
          <InputLabel>Sekcja</InputLabel>
          <Select
            onChange={(e) => setValue("sectionId", `${e.target.value}`)}
            value={values.sectionId || ""}
          >
            <MenuItem value="">
              <em>- dowolna sekcja -</em>
            </MenuItem>
            {(sections as IdName[]).map((val, i) => (
              <MenuItem value={val.id} key={i}>
                {val.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};
