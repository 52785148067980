export const formatAsCost = (cost: number): string => {
    return cost ? addThousandsSeparator(cost.toFixed(0)) + ' zł' : '-';
}

export const formatAsHourlyCost = (cost: number, formatAsGross = false): string => {

    const GROSS_OVERHEAD = 1.66;

    return (cost * (formatAsGross ? GROSS_OVERHEAD : 1)).toFixed(0) + ' zł / godz.'
}


export const addThousandsSeparator = (formattedValue: string): string => {
    return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}