import {FormControl, Switch} from "@material-ui/core";
import {EmployeeFilters} from "./EmployeeFilters";
import {formatAsCost} from "../../formatters/CostFormatter";

export type SummaryType = "hours" | "costs";

export const Toolbox = ({
                            onChange,
                            setSummaryType,
                            summaryType,
                            showFixedCosts,
                            setShowFixedCosts,
                            variableCosts,
                            fixedCosts
                        }: {
    onChange: (values: { [k: string]: string }) => void;
    setSummaryType: (value: SummaryType) => void;
    summaryType: SummaryType;
    showFixedCosts: boolean;
    setShowFixedCosts: (value: boolean) => void;
    variableCosts: number;
    fixedCosts: number
}) => {

    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                padding: "3px",
            }}
        >
            <div
                style={{
                    width: "70%",
                    display: "flex",
                    flexFlow: "column",
                    alignItems: "flex-start",
                }}
            >
                <EmployeeFilters onChange={(val) => onChange(val)}/>
            </div>
            <div
                style={{
                    width: "30%",
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "space-evenly",
                    paddingTop: "15px",
                }}
            >
                <div>
                    <span>Wyświetl koszty</span>
                    <br/>
                    <FormControl>
                        <Switch
                            value={summaryType === "hours"}
                            onChange={(_, val) => setSummaryType(val ? "costs" : "hours")}
                        />
                    </FormControl>
                    <br/>
                    {summaryType === "costs" ? <div>{showFixedCosts ? formatAsCost(fixedCosts + variableCosts) : formatAsCost(variableCosts)}</div> : null}
                </div>
                <div>
                    <span>Uwzględnij płace stałe</span>
                    <br/>
                    <FormControl>
                        <Switch
                            disabled={summaryType !== "costs"}
                            value={showFixedCosts}
                            onChange={(_, val) => setShowFixedCosts(val)}
                        />
                    </FormControl>
                    <br/>
                    {summaryType === "costs" && showFixedCosts ? <div>{formatAsCost(fixedCosts)}</div> : null}
                </div>
            </div>
        </div>
    );
};
