import { mapKeys, mapValues } from "lodash";
import React, { useCallback } from "react";
import {
  AutocompleteInput,
  Create,
  CreateProps,
  DateInput,
  required,
  SimpleForm,
  TextInput,
  useMutation,
  useNotify,
  useRedirect,
} from "react-admin";
import { getEmployees } from "../../context/EmployeesContext";
import { Employee } from "../employee/EmployeeType";

export const WorkDayCreate = (props: CreateProps) => {
  const employees = getEmployees();
  const redirect = useRedirect();
  const notify = useNotify();

  const [mutate] = useMutation();
  const save = useCallback(
    async (values) => {
      const startTime = new Date(`${values.date}T${values.startTime}`);
      const endTime = new Date(`${values.date}T${values.endTime}`);

      if (endTime < startTime) {
        return {
          endTime:
            "Data zakończenia nie może być wcześniejsza niż data rozpoczęcia",
        };
      }
      try {
        await mutate(
          {
            type: "create",
            resource: "work-day",
            payload: {
              data: {
                ...values,
                startTime,
                endTime,
              },
            },
          },
          { returnPromise: true }
        );

        notify("Dzień pracy został pomyślnie dodany", "info");
        redirect("list", "/work-day");
      } catch (error) {
        if (error.message && Array.isArray(error.message.message)) {
          return mapValues(
            mapKeys(error.message.message, (value) =>
              value
                .match(/attributes\.[a-zA-Z]{1,}/)[0]
                .replace("attributes.", "")
            ),
            (value) => value.replace(/attributes\.[a-zA-Z]{1,}\s/, "")
          );
        }

        notify(error && (error.message?.message || error.message), "error");
      }
    },
    [mutate, redirect, notify]
  );

  return (
    <Create {...props} title={"Dodaj dzień pracy / urlop"}>
      <SimpleForm
        save={save}
        initialValues={{
          startTime: "08:00",
          endTime: "16:00",
        }}
      >
        <AutocompleteInput
          source="employeeId"
          choices={employees}
          optionText={(choice: Employee) =>
            `${choice.firstName} ${choice.lastName}`
          }
        />
        <DateInput source="date" validate={[required()]} />
        <TextInput source="startTime" type="time" validate={[required()]} />
        <TextInput source="endTime" type="time" validate={[required()]} />
        <TextInput source="description" maxLength="50" />
      </SimpleForm>
    </Create>
  );
};
