import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Title, useDataProvider } from "react-admin";
import Card from "@material-ui/core/Card";
import plLocale from "@fullcalendar/core/locales/pl";
import { useTimespan } from "../../context/TimespanContext";
import { getEmployees } from "../../context/EmployeesContext";
import { each, keyBy, sum, sumBy, values } from "lodash";
import usePrevious from "use-previous";
import { getRoundedHours } from "../../components/DurationField";
import { checkWorkdayWarning } from "./WorkdayWarning";
import dayjs from "dayjs";
import WorkdayCell from "./WorkdayCell";
import DayOffCell from "./DayOffCell";
import { SummaryType, Toolbox } from "./Toolbox";
import { calculateCostsPerDay } from "../../helpers/costsPerDay";
import { Agreement } from "../employee/agreement/AgreementType";
import {formatAsCost} from "../../formatters/CostFormatter";

type DaySummary = {
  duration: number;
  estimatedCost: number;
  realCost: number;
};

const Calendar = () => {
  const { month, year } = useTimespan();
  const [events, setEvents] = useState<any[]>([]);
  const [summaryType, setSummaryType] = useState<SummaryType>("hours");
  const [totalDays, setTotalDays] = useState<{ [k: string]: DaySummary }>({});
  const [fixedCosts, setFixedCosts] = useState<{ [k: number]: number }>({});
  const [variableCosts, setVariableCosts] = useState<number>(0);
  const [showFixedCosts, setShowFixedCosts] = useState<boolean>(false);
  const dataProvider = useDataProvider();
  const employeesById = keyBy(getEmployees(), "id");
  const [currentTimespan, setCurrentTimespan] = useState("");
  const [positionFilters, setPositionFilters] = useState<{
    [k: string]: string;
  }>({});
  const prevFilters = usePrevious(positionFilters);
  const calendarRef = React.createRef();

  const onChange = (values: { [k: string]: string }) => {
    const filters: { [k: string]: string } = {};

    each(values, (val, key) => {
      if (val !== "") {
        filters[key] = val;
      }
    });

    setPositionFilters(filters);
  };

  useEffect(() => {
    if (
      calendarRef.current &&
      (currentTimespan !== `${year}-${month}` ||
        prevFilters !== positionFilters)
    ) {
      const api = (calendarRef.current as any).getApi();
      const firstDay = new Date(+(year || 0), +(month || 1), 1);
      api.gotoDate(firstDay);

      Promise.all([
        dataProvider.getList("agreement", {
          pagination: { page: 1, perPage: 2500 },
          sort: { field: "id", order: "ASC" },
          filter: {
            date:
              firstDay.toISOString().substr(0, 10) +
              "+" +
              dayjs(firstDay).endOf("month").toISOString().substr(0, 10),
            ...positionFilters,
          },
        }),
        dataProvider.getList("work-day", {
          pagination: { page: 1, perPage: 3000 },
          sort: { field: "id", order: "ASC" },
          filter: {
            month,
            year,
            positionFilters,
          },
        }),
      ]).then(([{ data: agreements }, { data }]) => {
        const days: { [k: string]: DaySummary } = {};
        setCurrentTimespan(`${year}-${month}`);


        const fixedRateAgreements = agreements.filter(agreement => !agreement.isHourlyRate);
        const hourlyRateAgreements = agreements.filter(agreement => agreement.isHourlyRate);

        setFixedCosts(
          calculateCostsPerDay(fixedRateAgreements as Agreement[], firstDay)
        );

        setVariableCosts(sumBy(data, 'estimatedCost'))

        setEvents(
          data
            .map((item) => {
              const employee = employeesById[item.employeeId];

              if (!employee) {
                return null;
              }

              if (!days[item.date]) {
                days[item.date] = {
                  duration: 0,
                  estimatedCost: 0,
                  realCost: 0,
                };
              }

              if (!item.isDayOff) {
                if (days[item.date]) {
                  days[item.date].duration += item.duration;
                }
              }

              days[item.date].estimatedCost += item.estimatedCost;
              days[item.date].realCost += item.realCost;

              return {
                id: item.id,
                start: item.isDayOff
                  ? dayjs(item.startTime).startOf("day").format()
                  : item.startTime,
                end: item.isDayOff
                  ? dayjs(item.endTime).startOf("day").add(2, "hours").format()
                  : item.endTime,
                display: item.isDayOff ? "block" : "list-item",
                extendedProps: {
                  workdayId: item.id,
                  isDayOff: item.isDayOff,
                  type: item.type,
                  start: new Date(item.startTime),
                  end: item.endTime ? new Date(item.endTime) : null,
                  firstName: employee.firstName,
                  lastName: employee.lastName,
                  position: employee.agreements[0]?.position.name,
                  duration: item.duration,
                  description: item.description,
                  estimatedCost: item.estimatedCost,
                  hourlyRate: hourlyRateAgreements.find(agreement => agreement.employeeId === employee.id)?.rate,
                  warning: checkWorkdayWarning({
                    startTime: item.startTime,
                    endTime: item.endTime,
                    duration: item.duration,
                    isToday: dayjs().isSame(dayjs(item.date), "day"),
                  }),
                },
              };
            })
            .filter((v) => !!v)
        );
        setTotalDays(days);
      });
    }
  }, [
    month,
    year,
    calendarRef,
    currentTimespan,
    dataProvider,
    employeesById,
    prevFilters,
    positionFilters,
  ]);

  return (
    <Card>
      <Title title={`Kalendarz pracy`} />

      <Toolbox
        onChange={onChange}
        setSummaryType={setSummaryType}
        summaryType={summaryType}
        setShowFixedCosts={setShowFixedCosts}
        showFixedCosts={showFixedCosts}
        variableCosts={variableCosts}
        fixedCosts={sum(values(fixedCosts))}
      />

      <FullCalendar
        ref={calendarRef as any}
        events={events}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        locale={plLocale}
        headerToolbar={{
          left: "",
          center: "",
          right: "",
        }}
        eventTimeFormat={{
          hour: "numeric",
          minute: "2-digit",
          meridiem: false,
        }}
        displayEventEnd
        stickyFooterScrollbar={false}
        eventContent={(data: any) => {
          return data.event.extendedProps.isDayOff ? (
            <DayOffCell {...data.event.extendedProps} />
          ) : (
            <WorkdayCell {...data.event.extendedProps} summaryType={summaryType} />
          );
        }}
        dayCellContent={(data) => {
          let result = "";
          let dayData = { duration: 0, realCost: 0, estimatedCost: 0 };
          if (!data.isFuture) {
            data.date.setHours(3);
            const current = data.date.toJSON().substring(0, 10);
            dayData = totalDays[current];
            const fixedCost = showFixedCosts
              ? fixedCosts[data.date.getDay() + 1] || 0
              : 0;
            if (dayData) {
              result =
                summaryType === "hours"
                  ? getRoundedHours(dayData.duration)
                  : formatAsCost(fixedCost + (dayData.realCost ? dayData.realCost : dayData.estimatedCost))
            }
          }

          return (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                padding: " 10px",
                boxShadow: "8px 8px 24px 0px rgba(66, 68, 90, 1)",
                backgroundColor: " #2196f3",
                color: "white",
              }}
            >
              <div
                style={{
                  fontSize: "1.1em",
                  fontWeight: "bold",
                }}
              >
                {data.dayNumberText}.
                {+month! > 8 ? +month! + 1 : `0${+month! + 1}`}
              </div>
              {result && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      color: "black",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    {result}
                  </div>
                  <div
                    style={{
                      color: "black",
                      fontSize: "0.6em",
                      fontWeight: "bold",
                    }}
                  >
                    {summaryType === "costs" &&
                      `${getRoundedHours(dayData.duration)} `}
                    Roboczogodzin
                  </div>
                </div>
              )}
            </div>
          );
        }}
      />
    </Card>
  );
};

export default Calendar;
