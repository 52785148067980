import React from "react";
import { Layout, LayoutProps } from "react-admin";
import { EmpoloyeesProvider } from "../context/EmployeesContext";
import { TimespanProvider } from "../context/TimespanContext";
import Menu from "./Menu";
import Sidebar from "./Sidebar";

const WrappedLayout = (props: LayoutProps) => (
  <TimespanProvider>
    <EmpoloyeesProvider>
      <Layout {...props} sidebar={Sidebar as any} menu={Menu} />
    </EmpoloyeesProvider>
  </TimespanProvider>
);

export default WrappedLayout;
