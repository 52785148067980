import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import { AuthProvider } from "ra-core";
import jwt, { JwtPayload } from "jsonwebtoken";

export default ({
  UserPoolId,
  ClientId,
}: {
  UserPoolId: string;
  ClientId: string;
}): AuthProvider => {
  const UserPool = new CognitoUserPool({
    UserPoolId,
    ClientId,
  });

  return {
    // called when the user attempts to log in
    login: ({ username, password, newPassword, email }) =>
      new Promise((resolve, reject) => {
        const user = new CognitoUser({
          Username: username || email,
          Pool: UserPool,
        });
        const authDetails = new AuthenticationDetails({
          Username: username,
          Password: password,
        });

        if (newPassword) {
          user.authenticateUser(authDetails, {
            onSuccess: (data) => {
              resolve(data);
            },
            onFailure: (err) => {
              reject(err);
            },
            newPasswordRequired: (data) => {
              user.completeNewPasswordChallenge(
                newPassword,
                { email },
                {
                  onSuccess: (data) => {
                    localStorage.setItem(
                      "token",
                      data.getAccessToken().getJwtToken()
                    );
                    resolve(data);
                  },
                  onFailure: (err) => {
                    reject(err);
                  },
                }
              );
            },
          });

          return;
        }

        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            localStorage.setItem("token", data.getAccessToken().getJwtToken());
            resolve(data);
          },
          onFailure: (err) => {
            reject(err);
          },
          newPasswordRequired: (data) => {
            (window as any).location.hash = "#/change-password";
            //   reject(data);
          },
        });
      }),
    logout: () => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.signOut();
      }
      return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }: { status: number }) => {
      if (status === 401 || status === 403) {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.signOut();
        }
        return Promise.reject();
      }
      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: ({ message, status, body }) =>
      new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession((err: Error) => {
            if (err) {
              reject();
              return;
            }
            resolve();
          });
        } else {
          reject();
        }
      }),
    getPermissions: () => {
      const user = UserPool.getCurrentUser();
      if (user != null && user.getUsername()) {
        const jwtBody = jwt.decode((user as any).storage.token);

        return Promise.resolve((jwtBody as JwtPayload)["cognito:groups"]);
      }

      return Promise.resolve([]);
    },
  };
};
