import React from "react";
import {
  Button,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  Record,
  NumberField,
  ReferenceManyField,
  Show,
  ShowProps,
  useShowController,
  BooleanField,
  TextField, usePermissions,
} from "react-admin";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import { Link } from "react-router-dom";
import { convertHexToDec } from "./helpers/CalculateCardNumber";
import {PERMISSION_ADMIN, PERMISSION_GENERAL_MANAGER} from "../../helpers/permissions";
import {formatAsCost, formatAsHourlyCost} from "../../formatters/CostFormatter";
const { ShowSplitter, BoxedShowLayout, RaBox } = require("ra-compact-ui");

const ViewTitle = ({ record }: { record?: { [k: string]: string } }) => {
  return (
    <>
      {record?.firstName} {record?.lastName}
    </>
  );
};

const AddNewAgreementButton = ({ record }: { record?: Record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/agreement/create",
      // Here we specify the initial record for the create view
      state: {
        record: {
          employeeId: record?.id,
          employee: `${record?.firstName} ${record?.lastName}`,
        },
      },
    }}
    label="Utwórz"
  >
    <CreateNewFolderIcon />
  </Button>
);

export const EmployeeView = (props: ShowProps): JSX.Element => {
  const { record } = useShowController(props);
  const { loaded, permissions } = usePermissions();

  const hasFullAccess = loaded && !!([PERMISSION_ADMIN, PERMISSION_GENERAL_MANAGER].filter(value => permissions.includes(value)).length)

  return (
    <Show {...props} title={<ViewTitle />} component="div">
      <ShowSplitter
        leftSide={
          <BoxedShowLayout>
            <RaBox display="flex">
              <RaBox display="flex" flexWrap="wrap" flexGrow={4}>
                <RaBox
                  flex="0 0 100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <NumberField source="id" />
                  <DateField source="createdAt" />
                  <DateField source="updatedAt" />
                  <FunctionField
                    source="cardId"
                    render={(record: Record | undefined) =>
                      record
                        ? record.cardId
                          ? convertHexToDec(record.cardId)
                          : "-"
                        : null
                    }
                  />
                  <BooleanField source="isActive" />
                </RaBox>
              </RaBox>
            </RaBox>
          </BoxedShowLayout>
        }
        rightSide={
          <>
            <h2>Umowa</h2>
            <ReferenceManyField
              reference="agreement"
              resource="agreement"
              target="employeeId"
              sort={{ field: "id", order: "DESC" }}
            >
              <Datagrid>
                <TextField source="position" />
                <FunctionField
                    source="dateFrom"
                    label="Zakres"
                    render={(record: Record | undefined) => record
                        ? `od ${record.dateFrom} ${record.dateTo ? 'do ' + record.dateTo : ''}`
                        : '-'}
                />
                <FunctionField
                    source="rate"
                    render={(record: Record | undefined) =>
                        record
                            ? record.isHourlyRate
                                ? formatAsHourlyCost(record.rate)
                                : formatAsCost(record.rate)
                            : null
                    }
                />
                  {hasFullAccess ? <EditButton /> : null}
              </Datagrid>
            </ReferenceManyField>
            {hasFullAccess ? <AddNewAgreementButton record={record}/> : null}
          </>
        }
      />
    </Show>
  );
};
