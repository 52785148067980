import React, { useEffect, useState } from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateInput,
  DeleteButton,
  EditButton,
  Filter,
  FilterProps,
  FunctionField,
  List,
  ListProps,
  TextField,
  downloadCSV,
} from "react-admin";
import { getEmployees } from "../../context/EmployeesContext";
import { useTimespan } from "../../context/TimespanContext";
import { Employee } from "../employee/EmployeeType";
import { ListActions } from "./list/Actions";
import { getWorkLogType, WorkLog } from "./WorkLogType";
const jsonExport = require("jsonexport");

const exporter = (items: WorkLog[]) => {
  const itemsForExport = items.map((item) => {
    const { time, employeeId } = item; // omit backlinks and author
    return {
      id: employeeId,
      time: new Date(time)
        .toLocaleString("pl-PL", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
        .replace(",", ""),
    };
  });
  jsonExport(
    itemsForExport,
    {
      headers: ["id", "time"],
    },
    (err: Error, csv: any) => {
      downloadCSV(csv, `workLogs${new Date().toLocaleString()}`);
    }
  );
};

export const WorkLogList = (props: ListProps) => {
  const employees = getEmployees();

  const { month: contextMonth, year: contextYear } = useTimespan();
  const [month, setMonth] = useState(contextMonth);
  const [year, setYear] = useState(contextYear);

  useEffect(() => {
    setMonth(contextMonth);
    setYear(contextYear);
  }, [contextMonth, contextYear]);

  if (employees.length === 0) {
    return null;
  }

  const optionRenderer = (choice: Employee) =>
    choice?.firstName
      ? `${choice.firstName} ${choice.lastName}`
      : `- WSZYSCY -`;

  const Filters = (props?: Omit<FilterProps, "children">) => (
    <Filter {...props}>
      <AutocompleteInput
        source="employeeId"
        choices={employees}
        optionText={optionRenderer}
      />
      <DateInput source={"time"} />
    </Filter>
  );

  console.log({ month, year });

  return (
    <List
      {...props}
      filterDefaultValues={{ isDeleted: false }}
      sort={{ field: "time", order: "desc" }}
      filter={{
        year,
        month,
      }}
      filters={<Filters />}
      exporter={exporter}
      actions={<ListActions />}
    >
      <Datagrid>
        <TextField source="employeeName" sortable={false} />
        <FunctionField
          source="type"
          render={(item: any) => getWorkLogType(item.type)}
        />
        {/* <TextField source="cardId" sortable={false} /> */}
        <FunctionField
          source="time"
          render={(item: any) =>
            new Date(item.time)
              .toLocaleString("pl-PL", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
              .replace(",", "")
          }
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
