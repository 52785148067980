import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import countdown from "countdown";

countdown.setLabels(
	' milisekunda| sekunda| minuta| godz.| dzień| week| month| year| decade| century| millennium',
	' millisec.| sec.| min.| godz.| d.| weeks| months| years| decades| centuries| millennia',
	', ',
	', ',
	'',
	function(n){ return n.toString(); });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
