import React from "react";
import {
  Button,
  Datagrid,
  EditButton,
  ReferenceManyField,
  Show,
  ShowButton,
  ShowProps,
  TextField,
  useShowController,
} from "react-admin";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import { Link } from "react-router-dom";

const ViewTitle = ({ record }: { record?: { [k: string]: string } }) => {
  return <span>Firma {record ? `${record.name}` : "-"}</span>;
};

const AddNewDepartmentButton = ({
  record,
}: {
  record?: { [k: string]: string };
}) => (
  <Button
    component={Link}
    to={{
      pathname: "/department/create",
      // Here we specify the initial record for the create view
      state: { record: { companyId: record?.id } },
    }}
    label="Utwórz"
  >
    <CreateNewFolderIcon />
  </Button>
);

export const CompanyView = (props: ShowProps): JSX.Element => {
  const {record} = useShowController(props);

  return (
    <Show {...props} title={<ViewTitle />}>
      <>
        <h2>Departamenty</h2>
        <ReferenceManyField
          reference="department"
          resource="department"
          target="companyId"
          sort={{ field: "id", order: "ASC" }}
        >
          <Datagrid>
            <TextField source="name" />
            <EditButton />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
        <AddNewDepartmentButton record={record} />
      </>
    </Show>
  );
};
