import dayjs from "dayjs";
import { Agreement } from "../pages/employee/agreement/AgreementType";

export const calculateCostsPerDay = (
  data: Agreement[],
  firstDay: Date
): { [k: number]: number } => {
  const firstDayString = firstDay.toISOString();
  const lastDayString = dayjs(firstDay).endOf("month").toISOString();
  const daysInMonth = Number(lastDayString.substr(8, 2));

  const costsPerDay: { [k: number]: number } = {};

  for (const agreement of data) {
    if (
      agreement.dateFrom < firstDayString &&
      ((agreement.dateTo && agreement.dateTo > lastDayString) ||
        !agreement.dateTo)
    ) {
      const cost = agreement.rate / daysInMonth;
      for (let i = 1; i <= daysInMonth; i++) {
        if (costsPerDay[i]) {
          costsPerDay[i] += cost;
        } else {
          costsPerDay[i] = cost;
        }
      }
    } else if (
      agreement.dateFrom < firstDayString &&
      (!agreement.dateTo || agreement.dateTo > firstDayString)
    ) {
      const lastDay = Number(agreement.dateTo!.substr(8, 2));
      const cost = agreement.rate / lastDay;
      for (let i = 1; i <= lastDay; i++) {
        if (costsPerDay[i]) {
          costsPerDay[i] += cost;
        } else {
          costsPerDay[i] = cost;
        }
      }
    } else if (
      agreement.dateFrom < lastDayString &&
      (!agreement.dateTo || agreement.dateTo > firstDayString)
    ) {
      const firstDay = Number(agreement.dateFrom.substr(8, 2));
      const cost = agreement.rate / (daysInMonth - firstDay + 1);
      for (let i = firstDay; i <= daysInMonth; i++) {
        if (costsPerDay[i]) {
          costsPerDay[i] += cost;
        } else {
          costsPerDay[i] = cost;
        }
      }
    }
  }
  return costsPerDay;
};
