import { mapKeys, mapValues } from "lodash";
import React, { useCallback } from "react";
import { Create, CreateProps, minLength, NumberInput, required, SimpleForm, TextInput, useMutation, useNotify, useRedirect } from "react-admin";
import { isIpAddress } from "../../helpers/ip";

export const CardReaderCreate = (props: CreateProps) => {

    const redirect = useRedirect();
    const notify = useNotify();

    const [mutate] = useMutation();
    const save = useCallback(
        async (values) => {
            try {
                await mutate({
                    type: 'create',
                    resource: 'card-reader',
                    payload: { data: values },
                }, { returnPromise: true });

                notify('Czytnik został pomyślnie dodany', 'info');
                redirect('list', '/card-reader');
            } catch (error) {
                if (error.message.message) {
                    return mapValues(mapKeys(error.message.message, (value) => value.match(/attributes\.[a-zA-Z]{1,}/)[0].replace('attributes.', '')), (value) => value.replace(/attributes\.[a-zA-Z]{1,}\s/, ''));
                }
                notify(error && (error.message?.message || error.message), 'error');
            }
        },
        [mutate, notify, redirect],
    );


    return (
    <Create {...props} title={"Dodaj czytnik"}>
        <SimpleForm save={save} initialValues={{isActive: true}}>
            <TextInput source="locationName" validate={[minLength(3),required()]} />
            <TextInput source="ip" validate={[isIpAddress, minLength(8),required()]} />
            <NumberInput source="port" validate={[required()]} />
        </SimpleForm>
    </Create>
    );
};