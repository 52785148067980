import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { maxLength, minLength, number, TextInput } from "react-admin";
import {
  convertDecToHex,
  convertHexToDec,
} from "../helpers/CalculateCardNumber";

export const CardNumberInput: FunctionComponent<any> = (props) => {
  const [value, setValue] = useState(
    props.record?.cardId ? convertHexToDec(props.record.cardId) : ""
  );

  useEffect(() => {
    if (props.record?.cardId) {
      props.record.cardId = convertHexToDec(props.record.cardId);
    }
  }, [props.record]);

  return (
    <div>
      <TextInput
        {...props}
        onChange={(event: ChangeEvent) => {
          setValue((event.target as any).value);
        }}
        validate={[minLength(10), maxLength(10), number()]}
      />
      <br />
      <i>{convertDecToHex(value)}</i>
    </div>
  );
};
