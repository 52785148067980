import { mapKeys, mapValues } from "lodash";
import React, { useCallback } from "react";
import Card from "@material-ui/core/Card";

import {
  CreateProps,
  DateTimeInput,
  required,
  SimpleForm,
  Title,
  useMutation,
  useNotify,
  useRedirect,
} from "react-admin";
import { EmployeesSelect } from "../../components/EmployeesSelect";

export const WorkLogRecalculate = (props: CreateProps) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const [mutate] = useMutation();
  const save = useCallback(
    async (values) => {
      try {
        try {
          await mutate(
            {
              type: "create",
              resource: "work-log-recalculate",
              payload: {
                data: {
                  ...values,
                },
              },
            },
            { returnPromise: true, onSuccess: () => {} }
          );
        } catch (e) {
          console.error(e);
          // @todo use another method to this request
        }

        notify("Odczyty zostały pomyślnie przeliczone", "info");
        return redirect("list", "/work-log");
      } catch (error) {
        debugger;
        if (error.message.message) {
          return mapValues(
            mapKeys(error.message.message, (value) =>
              value
                .match(/attributes\.[a-zA-Z]{1,}/)[0]
                .replace("attributes.", "")
            ),
            (value) => value.replace(/attributes\.[a-zA-Z]{1,}\s/, "")
          );
        }
        notify(error && (error.message?.message || error.message), "error");
      }
    },
    [mutate, redirect, notify]
  );

  return (
    <Card>
      <Title title={`Poprawa oznaczeń wejść/wyjść`} />
      <SimpleForm save={save}>
        <DateTimeInput
          source="dateFrom"
          label="Data od"
          validate={[required()]}
        />
        <DateTimeInput
          source="dateTo"
          label="Data do"
          validate={[required()]}
        />
        <EmployeesSelect source={"employeeId"} />
      </SimpleForm>
    </Card>
  );
};
