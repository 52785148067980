import { mapKeys, mapValues } from "lodash";
import React, { useCallback } from "react";
import Card from "@material-ui/core/Card";

import {
  CreateProps,
  DateTimeInput,
  Loading,
  required,
  SimpleForm,
  Title,
  useGetOne,
  useMutation,
  useNotify,
  useRedirect,
} from "react-admin";
import { useParams } from "react-router";

export const CardReaderSynchronize = (props: CreateProps) => {
  const { id } = useParams<{ id: string }>();
  const redirect = useRedirect();
  const notify = useNotify();

  const [mutate] = useMutation();
  const save = useCallback(
    async (values) => {
      try {
        try {
          await mutate(
            {
              type: "create",
              resource: "card-reader-synchronize",
              payload: {
                data: {
                  deviceId: id,
                  ...values,
                },
              },
            },
            { returnPromise: true, onSuccess: () => {} }
          );
        } catch (e) {
          console.error(e);
          // @todo use another method to this request
        }

        notify("Dane czytnika zostały pomyślnie zsynchronizowane", "info");
        return redirect("list", "/card-reader");
      } catch (error) {
        debugger;
        if (error.message.message) {
          return mapValues(
            mapKeys(error.message.message, (value) =>
              value
                .match(/attributes\.[a-zA-Z]{1,}/)[0]
                .replace("attributes.", "")
            ),
            (value) => value.replace(/attributes\.[a-zA-Z]{1,}\s/, "")
          );
        }
        notify(error && (error.message?.message || error.message), "error");
      }
    },
    [mutate, redirect, notify, id]
  );

  const { data, loading, error } = useGetOne("card-reader", id);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <Card>
      <Title
        title={`Synchronizacja brakujących logów z czytnika ${data?.locationName}`}
      />
      <SimpleForm save={save}>
        <DateTimeInput
          source="dateFrom"
          label="Data od"
          validate={[required()]}
        />
        <DateTimeInput
          source="dateTo"
          label="Data do"
          validate={[required()]}
        />
      </SimpleForm>
    </Card>
  );
};
