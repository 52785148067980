import React from "react";
import { AutocompleteInput, Validator } from "react-admin";
import { getEmployees } from "../context/EmployeesContext";
import { Employee } from "../pages/employee/EmployeeType";

export const EmployeesSelect = ({
  source,
  ...rest
}: {
  source: string;
  allowEmpty?: boolean;
  validate?: Validator | Validator[];
  disabled?: boolean;
}) => {
  const optionRenderer = (choice: Employee) =>
    choice?.firstName
      ? `${choice.firstName} ${choice.lastName}`
      : `- WSZYSCY -`;

  const employees = getEmployees();

  return (
    <AutocompleteInput
      source={source}
      choices={employees}
      label={"Pracownik"}
      optionText={optionRenderer}
      {...rest}
    />
  );
};

EmployeesSelect.defaultProps = {
  allowEmpty: true,
};
