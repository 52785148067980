import {mapWarningToColor, WorkdayWarning} from "./WorkdayWarning";
import {getHumanDuration, getHumanTime} from "../../components/DurationField";
import React from "react";
import {useHistory} from "react-router-dom";
import {formatAsCost, formatAsHourlyCost} from "../../formatters/CostFormatter";
import {SummaryType} from "./Toolbox";

export interface WorkdayCellDataInterface {
    workdayId: string;
    start: Date;
    end: Date;
    firstName: string;
    lastName: string;
    position: string;
    duration: number;
    estimatedCost: number;
    hourlyRate: number;
    warning: WorkdayWarning;
    summaryType: SummaryType;
}


const WorkdayCell = ({workdayId, start, end, firstName, lastName, position, duration, estimatedCost, hourlyRate, warning, summaryType}: WorkdayCellDataInterface) => {

    const history = useHistory();

    return (<div
        onClick={() => history.push(`/work-day/${workdayId}`)}
        style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.5)",
            padding: "3px",
            backgroundColor: mapWarningToColor(warning),
            cursor: "pointer",
        }}>
        <div
            style={{
                width: "70%",
                display: "flex",
                flexFlow: "column",
                alignItems: "flex-start"
            }}>
            <div
                style={{
                    fontSize: "1.1em",
                    fontWeight: "bold",
                    textOverflow: "ellipsis",
                    width: "100%",
                    overflow: "hidden"
                }}>{`${firstName.substr(0, 1)}. ${lastName}`}</div>
            <div
                style={{
                    fontSize: "0.8em",
                    textOverflow: "ellipsis",
                    width: "100%",
                    overflow: "hidden"
                }}>{position}
            </div>
        </div>
        <div
            style={{
                width: "30%",
                display: "flex",
                flexFlow: "column",
                alignItems: "flex-end"
            }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1.1em",
                    fontWeight: "bold"
                }}>
                {summaryType === 'costs'
                    ? formatAsCost(estimatedCost)
                    : getHumanDuration(duration)
                }
            </div>
            <div
                style={{
                    fontSize: "0.8em",
                }}>
                {summaryType === 'costs'
                    ? formatAsHourlyCost(hourlyRate, true)
                    : `${start && getHumanTime(start)} - ${end ? getHumanTime(end) : '__:__'}`
                }
            </div>
        </div>
    </div>)
};

export default WorkdayCell;

