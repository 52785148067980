export enum WorkdayWarning {
    NO_EXIT = 'no_exit',
    IS_WORKING = 'is_working',
    LONG_HOURS = 'long_hours',
    SHORT_HOURS = 'short_hours'
}

export interface WorkdayWarningData {
    startTime: string;
    endTime: string;
    duration: number;
    isToday: boolean;

}

export const checkWorkdayWarning = (data: WorkdayWarningData): WorkdayWarning | null => {

    if (!data.endTime && !data.isToday) return WorkdayWarning.NO_EXIT;
    if (data.duration > 60 * 60 * 14) return WorkdayWarning.LONG_HOURS;
    if (!data.endTime && data.isToday) return WorkdayWarning.IS_WORKING;
    if (data.duration < 60 * 60) return WorkdayWarning.SHORT_HOURS;

    return null;
}

export const mapWarningToColor = (warning: WorkdayWarning): string => {
    switch (warning) {
        case WorkdayWarning.SHORT_HOURS:
        case WorkdayWarning.LONG_HOURS:
            return 'darkorange'
        case WorkdayWarning.IS_WORKING:
            return 'lightblue';
        case WorkdayWarning.NO_EXIT:
            return 'lightcoral';
    }
}