import React, { useCallback } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Loading, SelectInput, SimpleForm, Title, useGetOne, useMutation, useNotify, useQuery, useRedirect } from 'react-admin';
import { useParams } from "react-router";

export const EmployeeAddCard = () => {
    const {id} = useParams<{id: string}>();
    const redirect = useRedirect();
    const notify = useNotify();

    const [mutate] = useMutation();

    const save = useCallback(
        async (values) => {
            try {
                await mutate({
                    type: 'update',
                    resource: 'employee-card',
                    payload: { 
                        id,
                        data: values 
                    },
                }, { returnPromise: true });

                notify('Dane pracownika zostały pomyślnie zapisane', 'info');
                redirect('list', '/employee')
            } catch (error) {
                notify('Wystąpił błąd podczas dodawania karty pracownika', 'error');
            }
        },
        [mutate, redirect, notify, id],
    );

    const { data: employee, loading, error } = useGetOne('employee', id);

    const { data: readers, loading: readersLoading, error: readersError } = useQuery({
            type: 'getList',
            resource: 'card-reader',
            payload: {
                pagination: {
                    page: 1,
                    perPage: 100
                }
            }
        });
    if (loading || readersLoading) { return <Loading />; }
    if (error || readersError) { return <p>ERROR: {readersError.message}</p>; }

    return (
    <Card>
        <Title title={`Dodaj kartę dla pracownika ${employee!.firstName} ${employee!.lastName}`} />
        <CardContent>
            <SimpleForm save={save}>
                <SelectInput label="Wybierz czytnik" source="cardReaderId" choices={readers.map((item: {id: number, locationName: string}) =>({id: item.id, name: item.locationName}))} />
            </SimpleForm>
        </CardContent>
    </Card>
    )
}