import countdown from "countdown";
import _ from "lodash";
import React from "react";
import {
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  FunctionField,
  List,
  ListProps,
  Record,
} from "react-admin";
import TimeField from "../../components/TimeField";
import { getEmployees } from "../../context/EmployeesContext";

export const WorkTodayList = (props: ListProps) => {
  const employees = _.keyBy(getEmployees(), "id");

  return (
    <List
      {...props}
      title={"Obecnie w pracy"}
      actions={false}
      bulkActionButtons={false}
      sort={{ field: "date", order: "desc" }}
      filterDefaultValues={{ endTime: null }}
    >
      <Datagrid>
        <FunctionField
          source="employeeId"
          render={(record: Record | undefined) => {
            if (!record) {
              return null;
            }
            const employee = employees[record.employeeId];
            return employee
              ? `${employee.firstName} ${employee.lastName}`
              : "-";
          }}
        />
        <DateField source="date" />
        <TimeField source="startTime" />
        <FunctionField
          sortable={false}
          source="duration"
          render={(record: Record | undefined) => {
            if (!record) {
              return null;
            }
            return (
              <>
                {countdown(
                  new Date(record.startTime).getTime(),
                  null,
                  countdown.HOURS | countdown.MINUTES
                ).toString()}
              </>
            );
          }}
        />
        <EditButton basePath={"/work-day"} />
        <DeleteButton basePath={"/work-day"} />
      </Datagrid>
    </List>
  );
};
