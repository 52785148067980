import React, { cloneElement } from "react";
import {
  Button,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
} from "react-admin";
import Sync from "@material-ui/icons/Sync";

export const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
    basePath,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />

      <Button href={`/#/work-day-recalculate`} label="Przelicz godziny pracy">
        <Sync />
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={3000}
      />
    </TopToolbar>
  );
};
