import * as React from "react";
import { WorkTodayList } from "../work-day/WorkTodayList";
const Dashboard = () => (
  <WorkTodayList
    resource={"work-day"}
    basePath={process.env.REACT_APP_API_URL}
  />
);

export default Dashboard;
