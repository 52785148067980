import * as React from "react";
import { TextFieldProps, useRecordContext } from "react-admin";
import { WorkDayTypes } from "../pages/work-day/WorkDayType";

const WorkDayTypeField = (props: TextFieldProps) => {
  const { source } = props;
  const record = useRecordContext(props);

  return source && record[source] !== undefined ? (
    <>{WorkDayTypes.find((v) => v.id === record[source])?.name}</>
  ) : null;
};

export default WorkDayTypeField;
