import { mapKeys, mapValues } from "lodash";
import React, { useCallback } from "react";
import {
  AutocompleteInput,
  DateInput,
  Edit,
  EditProps,
  required,
  SimpleForm,
  TextInput,
  useMutation,
  useNotify,
  useRedirect,
} from "react-admin";
import { WorkDaySelect } from "../../components/WorkDayTypeSelect";
import { getEmployees } from "../../context/EmployeesContext";
import { Employee } from "../employee/EmployeeType";

export const DayOffEdit = (props: EditProps) => {
  const employees = getEmployees();
  const redirect = useRedirect();
  const notify = useNotify();

  const [mutate] = useMutation();
  const save = useCallback(
    async (values) => {
      if (values.endTime < values.date) {
        return {
          endTime:
            "Data zakończenia nie może być wcześniejsza niż data rozpoczęcia",
        };
      }
      try {
        await mutate(
          {
            type: "update",
            resource: "day-off",
            payload: {
              id: values.id,
              data: {
                ...values,
                startTime: new Date(`${values.date}T08:00:00`),
                endTime: new Date(`${values.endTime}T16:00:00`),
              },
            },
          },
          { returnPromise: true }
        );

        notify("Urlop został pomyślnie zapisany", "info");
        redirect("list", "/day-off");
      } catch (error) {
        if (error.message?.message) {
          return mapValues(
            mapKeys(error.message.message, (value) =>
              value
                .match(/attributes\.[a-zA-Z]{1,}/)[0]
                .replace("attributes.", "")
            ),
            (value) => value.replace(/attributes\.[a-zA-Z]{1,}\s/, "")
          );
        }
        notify(error && (error.message?.message || error.message), "error");
      }
    },
    [mutate, redirect, notify]
  );

  return (
    <Edit {...props}>
      <SimpleForm save={save}>
        <AutocompleteInput
          source="employeeId"
          choices={employees}
          optionText={(choice: Employee) => `${choice.firstName} ${choice.lastName}`}
        />
        <WorkDaySelect isDayOff source="type" validate={[required()]} />
        <DateInput source="date" validate={[required()]} />
        <DateInput source="endTime" validate={[required()]} />
        <TextInput source="description" maxLength="50" />
      </SimpleForm>
    </Edit>
  );
};
