import { mapKeys, mapValues } from "lodash";
import React, { useCallback } from "react";
import { BooleanInput, CreateProps, Edit, minLength, NumberInput, required, SimpleForm, TextInput, useMutation, useNotify, useRedirect } from "react-admin";
import { isIpAddress } from "../../helpers/ip";

export const CardReaderEdit = (props: CreateProps) => {
    const redirect = useRedirect();
    const notify = useNotify();

    const [mutate] = useMutation();
    const save = useCallback(
        async (values) => {
            try {
                await mutate({
                    type: 'update',
                    resource: 'card-reader',
                    payload: { 
                        id: values.id,
                        data: values 
                    },
                }, { returnPromise: true });

                notify('Dane czytnika zostały pomyślnie zapisane', 'info');
                redirect('list', '/card-reader')
            } catch (error) {
                if (error.message.message) {
                    return mapValues(mapKeys(error.message.message, (value) => value.match(/attributes\.[a-zA-Z]{1,}/)[0].replace('attributes.', '')), (value) => value.replace(/attributes\.[a-zA-Z]{1,}\s/, ''));
                }
                notify(error && (error.message?.message || error.message), 'error');
            }
        },
        [mutate, redirect, notify],
    );


    return (
    <Edit {...props} title={"Edytuj czytnik"}>
        <SimpleForm save={save}>
            <TextInput source="locationName" validate={[minLength(3),required()]} />
            <TextInput source="ip" validate={[isIpAddress, minLength(8),required()]} />
            <NumberInput source="port" validate={[required()]} />
            <BooleanInput source="isActive" />
        </SimpleForm>
    </Edit>
    );
};