import React, { ReactNode, useEffect, useState } from "react";

interface TimespanContextInterface {
  month?: string;
  year?: string;
  setMonth: (val: string) => void;
  setYear: (val: string) => void;
}

const TimespanContext = React.createContext<TimespanContextInterface>({
  month: undefined,
  year: undefined,
  setMonth: () => {},
  setYear: () => {},
});

export const TimespanProvider = ({ children }: { children: ReactNode }) => {
  const [month, setMonth] = useState<string>(
    window.localStorage.getItem("month") || ""
  );
  const [year, setYear] = useState<string>(
    window.localStorage.getItem("year") || ""
  );

  useEffect(() => {
    if (!month || !year) {
      const date = new Date();
      setYear(date.getFullYear().toString());
      setMonth((date.getMonth()).toString());
    }
  }, [month, year, setMonth, setYear]);

  useEffect(() => {
    window.localStorage.setItem("month", month);
  }, [month]);

  useEffect(() => {
    window.localStorage.setItem("year", year);
  }, [year]);

  return (
    <TimespanContext.Provider value={{ month, setMonth, year, setYear }}>
      {children}
    </TimespanContext.Provider>
  );
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useTimespan = () => React.useContext(TimespanContext);
