import { mapKeys, mapValues } from "lodash";
import React, { useCallback } from "react";
import {
  Edit,
  EditProps,
  minLength,
  required,
  SimpleForm,
  TextInput,
  useMutation,
  useNotify,
  useRedirect,
} from "react-admin";

export const CompanyEdit = (props: EditProps) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const [mutate] = useMutation();
  const save = useCallback(
    async (values) => {
      try {
        await mutate(
          {
            type: "update",
            resource: "company",
            payload: {
              id: values.id,
              data: values,
            },
          },
          { returnPromise: true }
        );

        notify("Dane firmy zostały pomyślnie zapisane", "info");
        redirect("list", "/company");
      } catch (error) {
        if (error.message?.message) {
          return mapValues(
            mapKeys(error.message.message, (value) =>
              value
                .match(/attributes\.[a-zA-Z]{1,}/)[0]
                .replace("attributes.", "")
            ),
            (value) => value.replace(/attributes\.[a-zA-Z]{1,}\s/, "")
          );
        }
        notify(error && (error.message?.message || error.message), 'error');
      }
    },
    [mutate, redirect, notify]
  );

  return (
    <Edit {...props} title={"Edytuj firmę"}>
      <SimpleForm save={save}>
        <TextInput source="name" validate={[minLength(3), required()]} />
      </SimpleForm>
    </Edit>
  );
};
