import React from "react";
import {
  BooleanField,
  BooleanInput,
  Button,
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  Filter,
  FilterProps,
  Record,
  FunctionField,
  List,
  ListProps,
  TextField,
  TextInput,
} from "react-admin";
import Sync from "@material-ui/icons/Sync";

const Filters = (props?: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <TextInput source="locationName" />
    <BooleanInput source="isActive" />
    <BooleanInput source="isConnected" />
  </Filter>
);

export const CardReaderList = (props: ListProps) => (
  <List
    {...props}
    filters={<Filters />}
    filterDefaultValues={{ isActive: true }}
  >
    <Datagrid>
      <TextField source="locationName" />
      <TextField source="ip" />
      <TextField source="port" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <DateField source="lastSynced" showTime />
      <DateField source="lastLogTime" showTime />
      <BooleanField source="isConnected" />
      <BooleanField source="isActive" />
      <FunctionField
        source="logs"
        render={(record: Record | undefined) => (
          <Button
            color="secondary"
            href={`/#/card-reader-sync/${record?.id}`}
          >
            <Sync/>
            </Button>
        )}
      />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
