import * as React from "react";
import {TextFieldProps, useRecordContext} from "react-admin";
import dayjs from "dayjs";
import Duration from "dayjs/plugin/duration";

dayjs.extend(Duration);

export const getHumanDuration = (secondsTotal: number, asDays = false): string => {
    const duration = dayjs.duration(secondsTotal, 'second');

    if (asDays) {
        const days = duration.asDays() * 3;


        return `${days} ${days > 1 ? 'dni' : 'dzień'}`
    }

    return `${padLeadingZeros(Math.floor(duration.asHours()))}:${padLeadingZeros(duration.minutes())}`
};

export const getHumanTime = (date: Date): string => {
    return padLeadingZeros(date.getHours()) + ":" + padLeadingZeros(date.getMinutes())
};

export const getRoundedHours = (secondsTotal: number): string => {
    const duration = dayjs.duration(secondsTotal, 'second');

    return duration.asHours().toFixed(0)
};

const DurationField = (props: TextFieldProps) => {
    const {source} = props;
    const record = useRecordContext(props);

    if (!record || (record && source && !record[source])) {
        return null;
    }

    return <>{getHumanDuration(record[source!])}</>;
};

export const padLeadingZeros = (value: number, digits: number = 2) => {

    if (!value) return '00';

    let padded = value + "";
    while (padded.length < digits) padded = "0" + padded;
    return padded;
}

export default DurationField;
