import { CompanyList } from "../pages/company/CompanyList";
import { CompanyCreate } from "../pages/company/CompanyCreate";
import { CompanyEdit } from "../pages/company/CompanyEdit";
import { CompanyView } from "../pages/company/CompanyView";
import { DepartmentCreate } from "../pages/department/DepartmentCreate";
import { DepartmentEdit } from "../pages/department/DepartmentEdit";
import { SectionCreate } from "../pages/section/SectionCreate";
import { SectionEdit } from "../pages/section/SectionEdit";
import { PositionCreate } from "../pages/position/PositionCreate";
import { PositionEdit } from "../pages/position/PositionEdit";
import { DepartmentList } from "../pages/department/DepartmentList";
import { SectionList } from "../pages/section/SectionList";
import { PositionList } from "../pages/position/PositionList";
import { DivisionCreate } from "../pages/division/DivisionCreate";
import { DivisionEdit } from "../pages/division/DivisionEdit";
import { DivisionList } from "../pages/division/DivisionList";
import BusinessIcon from "@material-ui/icons/Business";
import { Resource } from "react-admin";
import { PERMISSION_ADMIN, PERMISSION_GENERAL_MANAGER } from "./permissions";
import { CardReaderList } from "../pages/card-reader/CardReaderList";
import { CardReaderCreate } from "../pages/card-reader/CardReaderCreate";
import { CardReaderEdit } from "../pages/card-reader/CardReaderEdit";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";

export const adminResources = (permissions: string[]) => {
  // if (
  //   !permissions.includes(PERMISSION_GENERAL_MANAGER) && // general manager should be able to fetch list
  //   !permissions.includes(PERMISSION_ADMIN)
  // ) {
  //   return [];
  // }

  const options = {
    permission: PERMISSION_ADMIN,
  };

  return [
    <Resource
      name={"card-reader"}
      list={CardReaderList}
      create={CardReaderCreate}
      edit={CardReaderEdit}
      icon={() => <ViewCarouselIcon />}
      {...{ options }}
    />,
    <Resource
      name={"company"}
      list={CompanyList}
      create={CompanyCreate}
      edit={CompanyEdit}
      show={CompanyView as any}
      icon={() => <BusinessIcon />}
      {...{ options }}
    />,
    <Resource
      name={"department"}
      create={DepartmentCreate}
      edit={DepartmentEdit}
      list={DepartmentList}
      {...{ options }}
    />,
    <Resource
      name={"division"}
      create={DivisionCreate}
      edit={DivisionEdit}
      list={DivisionList}
      {...{ options }}
    />,
    <Resource
      name={"section"}
      create={SectionCreate}
      edit={SectionEdit}
      list={SectionList}
      {...{ options }}
    />,
    <Resource
      name={"position"}
      create={PositionCreate}
      edit={PositionEdit}
      list={PositionList}
      {...{ options }}
    />,
  ];
};
