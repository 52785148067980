import { map, mapKeys, mapValues } from "lodash";
import { useCallback } from "react";
import { CreateProps, DateField, Edit, SelectInput, Show, SimpleForm, SimpleShowLayout, TextField, useMutation, useNotify, useRedirect } from "react-admin";
import { WorkLogActivityType } from "./WorkLogType";

export const WorkLogEdit = (props: CreateProps) => {
    const redirect = useRedirect();
    const notify = useNotify();

    const [mutate] = useMutation();
    const save = useCallback(
        async (values) => {
            try {
                await mutate({
                    type: 'update',
                    resource: 'work-log',
                    payload: { 
                        id: values.id,
                        data: values 
                    },
                }, { returnPromise: true });

                notify('Dane odczytu zostały pomyślnie zapisane', 'info');
                redirect('list', '/work-log')
            } catch (error) {
                if (error.message.message) {
                    return mapValues(mapKeys(error.message.message, (value) => value.match(/attributes\.[a-zA-Z]{1,}/)[0].replace('attributes.', '')), (value) => value.replace(/attributes\.[a-zA-Z]{1,}\s/, ''));
                }
                notify(error && error.message, 'error');
            }
        },
        [mutate, redirect, notify],
    );


    return (
        <>
        <Show {...props} title={"Edytuj odczyt"}>
            <SimpleShowLayout>
                <TextField source="employeeName" />
                <TextField source="cardId" />
                <DateField source="time" showTime />
            </SimpleShowLayout>
        </Show>
        <Edit {...props}>
            <SimpleForm save={save}>
                <SelectInput label="Zdarzenie" source="type" choices={map(WorkLogActivityType, (name, id) => ({id, name}))} />
            </SimpleForm>
        </Edit>
    </>
    );
};