import React, { useEffect, useState } from "react";
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListProps,
  Record,
  Filter,
  FilterProps,
  AutocompleteInput,
  DateInput,
  EditButton,
  DeleteButton,
} from "react-admin";
import { Employee } from "../employee/EmployeeType";
import { getEmployees } from "../../context/EmployeesContext";
import _ from "lodash";
import WorkDayTypeField from "../../components/WorkDayTypeField";
import { useTimespan } from "../../context/TimespanContext";

export const DayOffList = (props: ListProps) => {
  const employees = getEmployees();
  const { month: contextMonth, year: contextYear } = useTimespan();
  const [month, setMonth] = useState(contextMonth);
  const [year, setYear] = useState(contextYear);

  useEffect(() => {
    setMonth(contextMonth);
    setYear(contextYear);
  }, [contextMonth, contextYear]);

  const optionRenderer = (choice: Employee) =>
    choice?.firstName
      ? `${choice.firstName} ${choice.lastName}`
      : `- WSZYSCY -`;

  const Filters = (props?: Omit<FilterProps, "children">) => (
    <Filter {...props}>
      <AutocompleteInput
        source="employeeId"
        choices={employees}
        optionText={optionRenderer}
      />
      <DateInput source={"date"} />
    </Filter>
  );
  const employeesById = _.keyBy(getEmployees(), "id");

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filter={{
        year,
        month,
      }}
      filters={<Filters />}
      sort={{ field: "startTime", order: "ASC" }}
    >
      <Datagrid>
        <FunctionField
          source="employeeId"
          render={(record: Record | undefined) => {
            if (!record) {
              return null;
            }
            const employee = employeesById[record.employeeId];
            return `${employee.firstName} ${employee.lastName}`;
          }}
        />
        <DateField source="startTime" />
        <DateField source="endTime" />
        <FunctionField
          source="duration"
          render={(record: Record | undefined) => {
            if (!record) {
              return null;
            }
            return `${record.duration / 28800} d`;
          }}
        />
        <WorkDayTypeField source="type" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
