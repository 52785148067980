import {
  Edit,
  EditProps,
  minLength,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";

export const DivisionEdit = (props: EditProps) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={[minLength(3), required()]} />
      </SimpleForm>
    </Edit>
  );
};
