import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Loading, useAuthState, useDataProvider } from "react-admin";
import { Employee } from "../pages/employee/EmployeeType";

interface EmployeesContextInterface {
  items: Employee[];
  updateItems: () => void;
}

const EmployeesContext = React.createContext<EmployeesContextInterface>({
  items: [],
  updateItems: () => {},
});

export const EmpoloyeesProvider = ({ children }: { children: ReactNode }) => {
  const [items, setItems] = useState<Employee[]>([]);
  const { authenticated } = useAuthState();
  const dataProvider = useDataProvider();

  const updateItems = useCallback(() => {
    dataProvider
      .getList(`employee`, {
        sort: {
          field: "id",
          order: "asc",
        },
        filter: {
          isActive: true,
        },
        pagination: {
          page: 1,
          perPage: 500,
        },
      })
      .then((response) => {
        if (response && response.data) setItems(response.data as any);
      });
  }, [dataProvider]);

  useEffect(() => {
    if (authenticated && (window as any).location.hash !== "#/change-password")
      updateItems();
  }, [authenticated, updateItems]);

  return (
    <EmployeesContext.Provider value={{ items, updateItems }}>
      {items.length || (window as any).location.hash === "#/change-password" ? (
        children
      ) : (
        <Loading />
      )}
    </EmployeesContext.Provider>
  );
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const getEmployees = () => React.useContext(EmployeesContext).items;
export const updateEmployees = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useContext(EmployeesContext).updateItems();
